import { Command } from "@lookiero/messaging.js";

type UnmarkProductVariantAsCandidateParameters = {
  readonly legacyBoxId: string;
  readonly selectionId: string;
  readonly productVariantId: string;
  readonly boxId: string;
};
class UnmarkProductVariantAsCandidate extends Command {
  public readonly legacyBoxId: string;
  public readonly selectionId: string;
  public readonly productVariantId: string;
  public readonly boxId: string;

  public constructor({ legacyBoxId, selectionId, productVariantId, boxId }: UnmarkProductVariantAsCandidateParameters) {
    super();
    this.legacyBoxId = legacyBoxId;
    this.selectionId = selectionId;
    this.productVariantId = productVariantId;
    this.boxId = boxId;
  }

  protected messageName(): string {
    return "UnmarkProductVariantAsCandidate";
  }
}

export default UnmarkProductVariantAsCandidate;
