import { FC, useCallback, useState } from "react";
import { I18nMessage } from "@lookiero/i18n-react";
import useBoxSharedContext from "../_behaviors/useBoxSharedContext";
import SelectionProducts from "./selectionProducts/SelectionProducts";
import SelectionHeader from "./selectionHeader/SelectionHeader";
import Looks from "../looks/Looks";
import Button from "../../../shared/ui/uiKit/atoms/button/Button";
import { IconVariant } from "../../../shared/ui/uiKit/atoms/icon/Icon";
import SelectionI18n, { SELECTION_I18N_PREFIX } from "../../componentLibrary/_i18n/SelectionI18n";
import BoxPreviewSender from "../../componentLibrary/organisms/boxPreviewSender/BoxPreviewSender";
import useSendBoxPreview from "../_behaviors/useSendBoxPreview";
import { SelectionProductVariantProjection } from "../../../core/projection/selection/selection";
import BoxPreviewDetailCollapsible from "../boxPreviewDetail/BoxPreviewDetailCollapsible";
import useEnvironment from "../_behaviors/useEnvironment";
import { useTrackClick } from "../../../shared/tracking/infrastructure/useTrackClick";
import { Subsection, TrackingClick, TrackingPage } from "../../../shared/tracking/Tracker";
import { useTrackSelectDeselectProductVariant } from "../../../shared/tracking/infrastructure/useTrackSelectDeselectProductVariant";
import { useTrackPreviewSent } from "../../../shared/tracking/infrastructure/useTrackPreviewSent";
import { useViewPersonalShopper } from "../../../core/infrastructure/projection/personalShopper/react/useViewPersonalShopper";
import { useViewBoxPreviewByBoxId } from "../../../core/infrastructure/projection/boxPreview/react/useViewBoxPreviewByBoxId";
import { AlertContext } from "../../../core/projection/alert/alert";
import { SelectionAlertProjection, isSelectionAlert } from "../../../core/projection/alert/selectionAlert";
import { useListAlertsByBoxNumber } from "../../../core/infrastructure/projection/alert/react/useListAlertsByBoxNumber";
import { LookProjection } from "../../../core/projection/looks/look";
import { LookTemplate } from "../../../core/projection/looks/lookTemplate";
import { useListLooksForSelection } from "../../../core/infrastructure/projection/looks/react/useListLooksForSelection";
import { useAutomaticSelection } from "../_behaviors/useAutomaticSelection";
import "./selection.css";

interface HandleOnSaveFunctionArgs {
  readonly look: LookProjection;
  readonly lookTemplate: LookTemplate;
}
interface HandleOnSaveFunction {
  (args: HandleOnSaveFunctionArgs): void;
}

interface HandleOnClickBoxPreviewSenderFunctionArgs {
  readonly alerts: SelectionAlertProjection[];
}
interface HandleOnClickBoxPreviewSenderFunction {
  (args: HandleOnClickBoxPreviewSenderFunctionArgs): void;
}

interface HandleOnDeselectProductVariantFunctionArgs {
  readonly position: number;
  readonly productVariantId: string;
}
interface HandleOnDeselectProductVariantFunction {
  (args: HandleOnDeselectProductVariantFunctionArgs): void;
}

interface HandleOnClickProductFunctionArgs {
  readonly position: number;
  readonly productId: string;
}
interface HandleOnClickProductFunction {
  (args: HandleOnClickProductFunctionArgs): void;
}

type SelectionProps = {
  readonly isNoteCollapsed: boolean;
  readonly onToggleNote: () => void;
};

const Selection: FC<SelectionProps> = ({ isNoteCollapsed, onToggleNote }) => {
  const { box, selection } = useBoxSharedContext();
  const [personalShopper] = useViewPersonalShopper();
  const [looks] = useListLooksForSelection({ selection });

  const selectionProductVariantIds =
    selection?.products.map(
      (product) =>
        (
          product.productVariants.find(
            (productVariant) => productVariant.isSelected,
          ) as SelectionProductVariantProjection
        ).id,
    ) || [];

  const alerts = useListAlertsByBoxNumber({ boxNumber: String(box.boxNumber) });
  const { selectionAlerts } = useAutomaticSelection();
  const previewAlerts = selectionAlerts?.filter((alert) =>
    isSelectionAlert(alert) ? alert.context.includes(AlertContext.PREVIEW) : false,
  );

  const [boxPreview] = useViewBoxPreviewByBoxId({ boxId: box?.id });
  const { sendBoxPreview, state: sendBoxPreviewState } = useSendBoxPreview({
    boxId: box.id,
    selectionId: selection?.selectionId,
    selectionProductVariantIds,
    looks,
  });
  const trackSendBoxPreview = useTrackPreviewSent({
    boxId: box.id,
    section: TrackingPage.SELECTION,
    userId: box.customerId,
    psId: personalShopper?.id,
  });

  const handleSendBoxPreview = useCallback(async () => {
    await sendBoxPreview();
    trackSendBoxPreview({ boxNumber: String(box.boxNumber) });
  }, [box.boxNumber, sendBoxPreview, trackSendBoxPreview]);

  const trackClick = useTrackClick({
    boxId: box.id,
    psId: personalShopper?.id,
    section: TrackingPage.SELECTION,
    userId: box.customerId,
  });
  const trackSelectDeselectProductVariant = useTrackSelectDeselectProductVariant({
    boxId: box.id,
    psId: personalShopper?.id,
    section: TrackingPage.SELECTION,
    subsection: Subsection.SELECTION,
    userId: box.customerId,
  });

  const [lookCreatorVisible, setLookCreatorVisible] = useState(false);
  const showLookCreator = useCallback(() => setLookCreatorVisible(true), []);
  const hideLookCreator = useCallback(() => setLookCreatorVisible(false), []);

  const handleOnClickShowLookCreator = useCallback(() => {
    showLookCreator();
    trackClick({
      clickId: TrackingClick.ACCESS_CREATE_LOOK,
    });
  }, [showLookCreator, trackClick]);

  const handleOnDeselectProductVariant: HandleOnDeselectProductVariantFunction = useCallback(
    ({ position, productVariantId }) => {
      trackSelectDeselectProductVariant({
        position,
        productVariantId,
        select: false,
      });
    },
    [trackSelectDeselectProductVariant],
  );

  const handleOnClickProduct: HandleOnClickProductFunction = useCallback(
    ({ position, productId }) => {
      trackClick({
        clickId: TrackingClick.ITEM,
        position,
        productId,
      });
    },
    [trackClick],
  );

  const handleOnClickBoxPreviewSender: HandleOnClickBoxPreviewSenderFunction = useCallback(
    ({ alerts }) => {
      trackClick({
        clickId: TrackingClick.SEND_PREVIEW,
        alerts,
      });
    },
    [trackClick],
  );

  const handleOnChangedTemplate = useCallback(
    (lookTemplate: LookTemplate) =>
      trackClick({
        clickId: TrackingClick.CHANGE_LOOK_TEMPLATE,
        lookTemplate,
      }),
    [trackClick],
  );

  const handleOnSaveLook: HandleOnSaveFunction = useCallback(
    ({ look, lookTemplate }) => {
      hideLookCreator();
      trackClick({
        clickId: TrackingClick.CREATE_LOOK,
        look,
        lookTemplate,
      });
    },
    [hideLookCreator, trackClick],
  );

  const {
    experiments: { stopSendingPreviews, stopSendingPreviewsToCustomersWithoutGivingFeedback },
  } = useEnvironment();
  const stopSendingPreviewsExperiment = box.experiments.find(
    (experiment) => experiment.name === stopSendingPreviews.name,
  );
  const stopSendingPreviewsToCustomersWithoutGivingFeedbackExperiment = box.experiments.find(
    (experiment) => experiment.name === stopSendingPreviewsToCustomersWithoutGivingFeedback.name,
  );
  const previewDisabled =
    stopSendingPreviews.variant === stopSendingPreviewsExperiment?.variant ||
    stopSendingPreviewsToCustomersWithoutGivingFeedback.variant ===
      stopSendingPreviewsToCustomersWithoutGivingFeedbackExperiment?.variant;

  return (
    <section className="selection" data-testid="selection">
      <SelectionHeader
        alerts={alerts}
        boxPreviewStatus={boxPreview?.status}
        isNoteCollapsed={isNoteCollapsed}
        products={selection?.products}
        onToggleNote={onToggleNote}
      >
        {boxPreview ? (
          <BoxPreviewDetailCollapsible boxPreview={boxPreview} />
        ) : (
          <BoxPreviewSender
            enabled={!previewDisabled}
            looks={looks}
            previewAlerts={!previewDisabled ? previewAlerts : undefined}
            sendBoxPreviewState={sendBoxPreviewState}
            onClick={handleOnClickBoxPreviewSender}
            onSendBoxPreview={handleSendBoxPreview}
          />
        )}
        {!boxPreview && !previewDisabled && (
          <Button
            aria-label="selection-header-create-look"
            disabled={!Boolean(selection?.products.length !== 0)}
            icon={IconVariant.LOOK_TEMPLATE}
            onClick={handleOnClickShowLookCreator}
          >
            <I18nMessage id={SelectionI18n.CREATE_LOOK} prefix={SELECTION_I18N_PREFIX} />
          </Button>
        )}
      </SelectionHeader>

      <div className="selection__content">
        <div className="selection__preview-and-products">
          <SelectionProducts
            alerts={selectionAlerts}
            onClickProduct={handleOnClickProduct}
            onDeselectProductVariant={handleOnDeselectProductVariant}
          />
        </div>
        {!boxPreview && (
          <Looks
            creatorVisible={lookCreatorVisible}
            onChangedTemplate={handleOnChangedTemplate}
            onHideCreator={hideLookCreator}
            onSave={handleOnSaveLook}
          />
        )}
      </div>
    </section>
  );
};

export default Selection;
