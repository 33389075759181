import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import invariant from "ts-invariant";
import { Selection } from "../../../domain/selection/selection";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { SelectionsGetFunction, SelectionsSaveFunction } from "../../../domain/selection/selections";
import { httpSelectionsSelect } from "./httpSelectionsSelect";
import { httpSelectionsDeselect } from "./httpSelectionsDeselect";
import { httpSelectionsReplace } from "./httpSelectionsReplace";
import {
  ViewSelectionById,
  viewSelectionById,
  ViewSelectionByIdResult,
} from "../../../projection/selection/viewSelectionById";

interface ToDomainFunction {
  (selection: ViewSelectionByIdResult): Selection;
}

const toDomain: ToDomainFunction = (selection) => {
  invariant(selection, "Selection does not exist");

  return {
    aggregateId: selection.id,
    boxId: selection.boxId,
    boxNumber: selection.boxNumber,
    automaticSelectionStartedOn: selection.automaticSelectionStartedOn
      ? new Date(selection.automaticSelectionStartedOn)
      : null,
    automaticSelectionFinishedOn: selection.automaticSelectionFinishedOn
      ? new Date(selection.automaticSelectionFinishedOn)
      : null,
    productVariantIds: selection.productVariants.map(({ id }) => id),
    domainEvents: [],
  };
};

interface HttpSelectionsGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpSelectionsGetFunction extends SelectionsGetFunction<HttpSelectionsGetFunctionArgs> {}

const getSelection: HttpSelectionsGetFunction =
  ({ queryBus }) =>
  async (aggregateId) => {
    try {
      return toDomain(
        await queryBus<ViewSelectionById, ViewSelectionByIdResult>(viewSelectionById({ id: aggregateId })),
      );
    } catch (error) {
      return {
        aggregateId,
        automaticSelectionFinishedOn: null,
        automaticSelectionStartedOn: null,
        boxId: null,
        boxNumber: null,
        productVariantIds: [],
        domainEvents: [],
      };
    }
  };

interface HttpSelectionsSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpSelectionsSaveFunction extends SelectionsSaveFunction<HttpSelectionsSaveFunctionArgs> {}

const saveSelection: HttpSelectionsSaveFunction =
  ({ httpPost }) =>
  async (aggregateRoot) => {
    await Promise.all([
      await httpSelectionsSelect({ httpPost })(aggregateRoot),
      await httpSelectionsDeselect({ httpPost })(aggregateRoot),
      await httpSelectionsReplace({ httpPost })(aggregateRoot),
    ]);
  };

export type { HttpSelectionsSaveFunction };
export { getSelection, saveSelection };
