import { bootstrapWithReact } from "@lookiero/messaging.js";
import { PostMeWindowsMessengerChild } from "@lookiero/windows-messenger.js";
import { Container, decorate, injectable } from "inversify";
import getDecorators from "inversify-inject-decorators";
import { helpers, inversifyInterfaces } from "inversify-vanillajs-helpers";
import "reflect-metadata";
import packageInfo from "../../../package.json";
import { TYPES } from "./container.types";
import { EndpointFunction } from "@lookiero/i18n";
import { i18n } from "@lookiero/i18n-react";
import FetchHttpClient from "../../shared/delivery/infrastructure/FetchHttpClient";
import WindowNavigator from "../../shared/delivery/infrastructure/WindowNavigator";
import AuthCookieBasedFeatureToggle from "../../shared/featureToggle/AuthCookieBasedFeatureToggle";
import FeatureToggle from "../../shared/featureToggle/FeatureToggle";
import SentryLogger from "../../shared/logging/SentryLogger";
import CookieStorage from "../../shared/storage/infrastructure/domain/model/CookieStorage";
import { AsyncGtmTracker } from "../../shared/tracking/infrastructure/AsyncGtmTracker";
import FeatureToggleRouteFirewall from "../../ui/_firewall/FeatureToggleRouteFirewall";
import ToLegacy from "../../ui/views/toLegacy/ToLegacy";
import SendBoxPreview from "../domain/boxPreview/command/sendBoxPreview/SendBoxPreview";
import SendBoxPreviewHandler from "../domain/boxPreview/command/sendBoxPreview/SendBoxPreviewHandler";
import BoxPreviewSent from "../domain/boxPreview/event/BoxPreviewSent";
import SetLooksWhenBoxPreviewSent from "../domain/look/event/SetLooksWhenBoxPreviewSent";
import PrefetchMediaWhenBoxPreviewSent from "../domain/media/event/PrefetchMediaWhenBoxPreviewSent";
import MarkProductVariantAsCandidate from "../domain/selection/command/markProductVariantAsCandidate/MarkProductVariantAsCandidate";
import MarkProductVariantAsCandidateHandler from "../domain/selection/command/markProductVariantAsCandidate/MarkProductVariantAsCandidateHandler";
import UnmarkProductVariantAsCandidate from "../domain/selection/command/unmarkProductVariantAsCandidate/UnmarkProductVariantAsCandidate";
import UnmarkProductVariantAsCandidateHandler from "../domain/selection/command/unmarkProductVariantAsCandidate/UnmarkProductVariantAsCandidateHandler";
import CreateNotificationWhenBoxPreviewSent from "../domain/boxPreview/event/CreateNotificationWhenBoxPreviewSent";
import HttpBoxPreviews from "../infrastructure/domain/boxPreview/model/HttpBoxPreviews";
import HttpSelections from "../infrastructure/domain/selection/model/HttpSelections";
import LabsFrontLegacyWindowsMessenger from "../infrastructure/integration/LabsFrontLegacyWindowsMessenger";
import WindowsMessenger from "../infrastructure/integration/WindowsMessenger";
import isIframeEmbedded from "../infrastructure/integration/isIframeEmbedded";
import { fetchTranslations } from "../infrastructure/projection/translations/model/fetchTranslations";
import { translationExternalEndpoint } from "../infrastructure/projection/translations/model/translationEndpoint";
import Environment from "../projection/environment/model/Environment";
import { CustomerWindowsMessenger } from "../infrastructure/integration/customer/CustomerWindowsMessenger";
import { CommandBus, QueryBus } from "@lookiero/messaging";

const inSingletonScopeBinding = <T>(binding: inversifyInterfaces.BindingInSyntax<T>): void =>
  void binding.inSingletonScope();

let container: Container;

interface BootstrapFunctionArgs {
  readonly environment: Environment;
  readonly authToken: string | undefined;
  readonly messagingQueryBus: QueryBus;
  readonly messagingCommandBus: CommandBus;
}

interface BootstrapFunction {
  (args: BootstrapFunctionArgs): Promise<Container>;
}

const bootstrap: BootstrapFunction = async ({ environment, authToken, messagingQueryBus, messagingCommandBus }) => {
  if (container) {
    return container;
  }

  container = new Container();

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const register = helpers.register(container as any);
  const registerConstantValue = helpers.registerConstantValue(container as any);
  const registerDynamicValue = helpers.registerDynamicValue(container as any);
  /* eslint-enable @typescript-eslint/no-explicit-any */
  const { lazyInject } = getDecorators(container, true);

  /**
   * We need to explicitly decorate this abstract class
   */
  decorate(injectable(), WindowsMessenger);

  registerConstantValue(TYPES.Environment, environment);

  /**
   * Init GTM Tracker
   */
  let tracker;
  if (environment.tracking) {
    try {
      tracker = await AsyncGtmTracker.init({
        project: environment.tracking.project,
        gtmId: environment.tracking.gtmId,
      });
    } catch (error) {}
  }
  registerConstantValue(TYPES.Tracker, tracker);

  /**
   * Init Sentry Logger
   */
  let logger;
  if (environment.logging) {
    logger = new SentryLogger({
      environment: process.env.NODE_ENV === "production" ? "web-PROD" : "web-DEV",
      release: packageInfo.version,
      project: environment.logging.sentryProject,
      publicKey: environment.logging.sentryPublicKey,
    });
  }
  registerConstantValue(TYPES.Logger, logger);

  registerDynamicValue(
    TYPES.HttpClient,
    (context) => {
      const { labsBackUrl } = context.container.get<Environment>(TYPES.Environment);

      const labsBackAuthHeaders =
        authToken !== undefined
          ? {
              Authorization: `Bearer ${authToken}`,
            }
          : undefined;

      return new FetchHttpClient(labsBackUrl, labsBackAuthHeaders);
    },
    inSingletonScopeBinding,
  );

  registerDynamicValue(
    TYPES.Navigator,
    (context) => {
      const { labsBackUrl } = context.container.get<Environment>(TYPES.Environment);

      return new WindowNavigator(labsBackUrl);
    },
    inSingletonScopeBinding,
  );

  registerConstantValue(TYPES.ToLegacy, ToLegacy);

  register(TYPES.CookieStorage, [], inSingletonScopeBinding)(CookieStorage);

  registerDynamicValue(TYPES.I18nRootComponent, (context) => {
    const {
      internationalization: { defaultLocale, externalEndpoint },
    } = context.container.get<Environment>(TYPES.Environment);

    const translations: EndpointFunction[] = [
      translationExternalEndpoint({
        translationsUrl: externalEndpoint,
        projects: [["labs"], ["inventory-catalog"], ["checkout"], ["style-profile"]],
        defaultLocale,
      }),
    ];

    return i18n({
      fetchTranslation: fetchTranslations({ translations }),
      contextId: "LabsI18n",
    });
  });

  /**
   * Commands
   */
  register(TYPES.Selections, [TYPES.HttpClient], inSingletonScopeBinding)(HttpSelections);
  register(
    TYPES.MarkProductVariantAsCandidateHandler,
    [TYPES.Selections],
    inSingletonScopeBinding,
  )(MarkProductVariantAsCandidateHandler);
  register(
    TYPES.UnmarkProductVariantAsCandidateHandler,
    [TYPES.Selections],
    inSingletonScopeBinding,
  )(UnmarkProductVariantAsCandidateHandler);

  register(TYPES.BoxPreviews, [TYPES.HttpClient], inSingletonScopeBinding)(HttpBoxPreviews);
  register(TYPES.SendBoxPreviewHandler, [TYPES.BoxPreviews], inSingletonScopeBinding)(SendBoxPreviewHandler);

  /**
   * Process Managers
   */
  register(
    TYPES.CreateNotificationWhenBoxPreviewSent,
    [],
    inSingletonScopeBinding,
  )(CreateNotificationWhenBoxPreviewSent);
  register(TYPES.SetLooksWhenBoxPreviewSent, [], inSingletonScopeBinding)(SetLooksWhenBoxPreviewSent);
  register(
    TYPES.PrefetchMediaWhenBoxPreviewSent,
    [TYPES.Environment],
    inSingletonScopeBinding,
  )(PrefetchMediaWhenBoxPreviewSent);

  /**
   * Windows Messenger integration
   */
  register(TYPES.WindowsMessenger, [], inSingletonScopeBinding)(PostMeWindowsMessengerChild);
  register(
    TYPES.LabsFrontLegacyWindowsMessenger,
    [TYPES.WindowsMessenger],
    inSingletonScopeBinding,
  )(LabsFrontLegacyWindowsMessenger);

  /**
   * FeatureToggles
   */
  registerDynamicValue(
    TYPES.RealTimeBoxProductionAuthCookieBasedFeatureToggle,
    (context) => {
      const environment = context.container.get<Environment>(TYPES.Environment);
      const cookieStorage = context.container.get<CookieStorage>(TYPES.CookieStorage);

      return new AuthCookieBasedFeatureToggle(cookieStorage, environment.toggleFeatures.isRealTimeBoxProductionEnabled);
    },
    inSingletonScopeBinding,
  );
  registerDynamicValue(
    TYPES.PlanningAuthCookieBasedFeatureToggle,
    (context) => {
      const environment = context.container.get<Environment>(TYPES.Environment);
      const cookieStorage = context.container.get<CookieStorage>(TYPES.CookieStorage);

      return new AuthCookieBasedFeatureToggle(cookieStorage, environment.toggleFeatures.isPlanningEnabled);
    },
    inSingletonScopeBinding,
  );
  registerDynamicValue(
    TYPES.StockSurveyTypeformAuthCookieBasedFeatureToggle,
    (context) => {
      const environment = context.container.get<Environment>(TYPES.Environment);
      const cookieStorage = context.container.get<CookieStorage>(TYPES.CookieStorage);

      return new AuthCookieBasedFeatureToggle(cookieStorage, environment.toggleFeatures.isStockSurveyTypeformEnabled);
    },
    inSingletonScopeBinding,
  );
  registerDynamicValue(
    TYPES.AutomaticSelectionTypeformAuthCookieBasedFeatureToggle,
    (context) => {
      const environment = context.container.get<Environment>(TYPES.Environment);
      const cookieStorage = context.container.get<CookieStorage>(TYPES.CookieStorage);

      return new AuthCookieBasedFeatureToggle(
        cookieStorage,
        environment.toggleFeatures.isAutomaticSelectionTypeformEnabled,
      );
    },
    inSingletonScopeBinding,
  );
  registerDynamicValue(
    TYPES.CapacityAuthCookieBasedFeatureToggle,
    (context) => {
      const environment = context.container.get<Environment>(TYPES.Environment);
      const cookieStorage = context.container.get<CookieStorage>(TYPES.CookieStorage);

      return new AuthCookieBasedFeatureToggle(cookieStorage, environment.toggleFeatures.isCapacityEnabled);
    },
    inSingletonScopeBinding,
  );
  registerDynamicValue(
    TYPES.CustomerInsightsAuthCookieBasedFeatureToggle,
    (context) => {
      const environment = context.container.get<Environment>(TYPES.Environment);
      const cookieStorage = context.container.get<CookieStorage>(TYPES.CookieStorage);

      return new AuthCookieBasedFeatureToggle(cookieStorage, environment.toggleFeatures.isCustomerInsightsEnabled);
    },
    inSingletonScopeBinding,
  );

  /**
   * Firewalls
   */
  register(TYPES.CustomerWindowsMessenger, [TYPES.WindowsMessenger], inSingletonScopeBinding)(CustomerWindowsMessenger);
  registerDynamicValue(
    TYPES.RealTimeBoxProductionRouteFirewall,
    (context) => {
      const featureToggles = [
        context.container.get<FeatureToggle>(TYPES.RealTimeBoxProductionAuthCookieBasedFeatureToggle),
      ];
      const fallbackComponent = isIframeEmbedded() ? () => null : ToLegacy;

      return new FeatureToggleRouteFirewall(featureToggles, fallbackComponent);
    },
    inSingletonScopeBinding,
  );
  registerDynamicValue(
    TYPES.PlanningRouteFirewall,
    (context) => {
      const featureToggles = [context.container.get<FeatureToggle>(TYPES.PlanningAuthCookieBasedFeatureToggle)];
      const fallbackComponent = () => null;

      return new FeatureToggleRouteFirewall(featureToggles, fallbackComponent);
    },
    inSingletonScopeBinding,
  );
  registerDynamicValue(
    TYPES.CapacityRouteFirewall,
    (context) => {
      const featureToggles = [context.container.get<FeatureToggle>(TYPES.CapacityAuthCookieBasedFeatureToggle)];
      const fallbackComponent = () => null;

      return new FeatureToggleRouteFirewall(featureToggles, fallbackComponent);
    },
    inSingletonScopeBinding,
  );

  /**
   * Bootstrap
   */
  const { commandBus, eventBus, queryBus, component, uiEventBus } = bootstrapWithReact({
    queries: [],
    commands: [
      [MarkProductVariantAsCandidate, container.get(TYPES.MarkProductVariantAsCandidateHandler)],
      [UnmarkProductVariantAsCandidate, container.get(TYPES.UnmarkProductVariantAsCandidateHandler)],
      [SendBoxPreview, container.get(TYPES.SendBoxPreviewHandler)],
    ],
    processManagers: [
      [BoxPreviewSent, container.get(TYPES.CreateNotificationWhenBoxPreviewSent)],
      [BoxPreviewSent, container.get(TYPES.SetLooksWhenBoxPreviewSent)],
      [BoxPreviewSent, container.get(TYPES.PrefetchMediaWhenBoxPreviewSent)],
    ],
  });

  registerConstantValue(TYPES.UIEventBus, uiEventBus);
  registerConstantValue(TYPES.DomainEventBus, eventBus);
  registerConstantValue(TYPES.CommandBus, commandBus);
  registerConstantValue(TYPES.QueryBus, queryBus);
  registerConstantValue(TYPES.MessagingRootComponent, component);

  registerConstantValue(TYPES.MessagingQueryBus, messagingQueryBus);
  registerConstantValue(TYPES.MessagingCommandBus, messagingCommandBus);

  lazyInject(TYPES.DomainEventBus)(container.get(TYPES.Selections), "eventBus");
  lazyInject(TYPES.DomainEventBus)(container.get(TYPES.BoxPreviews), "eventBus");

  /**
   * lazyInject won't call the eventBus setter
   */
  const wm = container.get<LabsFrontLegacyWindowsMessenger>(TYPES.LabsFrontLegacyWindowsMessenger);
  wm.eventBus = uiEventBus;

  lazyInject(TYPES.MessagingCommandBus)(
    container.get(TYPES.CreateNotificationWhenBoxPreviewSent),
    "messagingCommandBus",
  );
  lazyInject(TYPES.MessagingCommandBus)(container.get(TYPES.SetLooksWhenBoxPreviewSent), "messagingCommandBus");
  lazyInject(TYPES.MessagingQueryBus)(container.get(TYPES.MarkProductVariantAsCandidateHandler), "messagingQueryBus");
  lazyInject(TYPES.MessagingQueryBus)(container.get(TYPES.UnmarkProductVariantAsCandidateHandler), "messagingQueryBus");
  lazyInject(TYPES.MessagingQueryBus)(container.get(TYPES.PrefetchMediaWhenBoxPreviewSent), "messagingQueryBus");

  return container;
};

export default bootstrap;
