import { CommandHandler } from "@lookiero/messaging.js";
import { QueryBus as MessagingQueryBus } from "@lookiero/messaging";
import Selection from "../../model/Selection";
import Selections from "../../model/Selections";
import MarkProductVariantAsCandidate from "./MarkProductVariantAsCandidate";
import {
  canMarkOrUnmarkProductVariantAsCandidate,
  CanMarkOrUnmarkProductVariantAsCandidateResult,
} from "../../../../projection/selection/canMarkOrUnmarkProductVariantAsCandidate";

class MarkProductVariantAsCandidateHandler implements CommandHandler<MarkProductVariantAsCandidate> {
  private readonly selections: Selections;
  private readonly messagingQueryBus!: MessagingQueryBus;

  public constructor(selections: Selections) {
    this.selections = selections;
  }

  public async execute({ legacyBoxId, productVariantId, boxId }: MarkProductVariantAsCandidate): Promise<void> {
    const selection: Selection | undefined = await this.selections.byLegacyBoxId(legacyBoxId);

    if (!selection) {
      throw new Error(`Selection for the provided legacy-box-id does not exist: ${legacyBoxId}`);
    }

    const canMarkOrUnmarkProductVariantAsCandidateResult: CanMarkOrUnmarkProductVariantAsCandidateResult =
      await this.messagingQueryBus(canMarkOrUnmarkProductVariantAsCandidate({ boxNumber: legacyBoxId, boxId }));

    if (!canMarkOrUnmarkProductVariantAsCandidateResult) {
      throw new Error(`Cannot mark product variant as candidate: ${productVariantId}`);
    }

    selection.markProductVariantAsCandidate(productVariantId);

    await this.selections.markProductVariantAsCandidate(selection, productVariantId);
  }
}

export default MarkProductVariantAsCandidateHandler;
