import { DomainEvent } from "@lookiero/messaging.js";

class ProductVariantUnmarkedAsCandidate extends DomainEvent {
  public readonly selectionId: string;
  public readonly productVariantId: string;

  public constructor(selectionId: string, productVariantId: string) {
    super();

    this.selectionId = selectionId;
    this.productVariantId = productVariantId;
  }

  public messageName(): string {
    return "ProductVariantUnmarkedAsCandidate";
  }
}

export default ProductVariantUnmarkedAsCandidate;
