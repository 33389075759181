import { isProductVariantSelected } from "../../../domain/selection/model/ProductVariantSelected";
import { HttpSelectionsSaveFunction } from "./httpSelections";

interface HttpSelectionsSelectFunction extends HttpSelectionsSaveFunction {}

const httpSelectionsSelect: HttpSelectionsSelectFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents, boxId }) => {
    const productVariantSelected = domainEvents.find(isProductVariantSelected);

    if (!productVariantSelected) {
      return;
    }

    await httpPost({
      endpoint: "/select-product-variant",
      body: {
        selection_id: aggregateId,
        box_id: boxId,
        product_variant_id: productVariantSelected.productVariantId,
      },
    });
  };

export { httpSelectionsSelect };
