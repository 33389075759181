import IsCandidate from "../../../../domain/selection/model/IsCandidate";
import ProductVariant from "../../../../domain/selection/model/ProductVariant";
import Selection from "../../../../domain/selection/model/Selection";

type SelectionProductVariantResponseDTO = {
  readonly id: string;
  readonly isSelected: boolean;
  readonly isCandidate: boolean;
};

type SelectionProductResponseDTO = {
  readonly id: string;
  readonly productVariants: SelectionProductVariantResponseDTO[];
};

type SelectionResponse = {
  readonly selectionId: string;
  readonly boxId: string;
  readonly automaticSelectionStartedOn: string | null;
  readonly automaticSelectionFinishedOn: string | null;
  readonly products: SelectionProductResponseDTO[];
};

const selectionFromResponse = (
  legacyBoxId: string,
  { selectionId, boxId, automaticSelectionStartedOn, automaticSelectionFinishedOn, products }: SelectionResponse,
): Selection =>
  new Selection({
    legacyBoxId,
    selectionId,
    boxId,
    automaticSelectionStartedOn: automaticSelectionStartedOn ? new Date(automaticSelectionStartedOn) : null,
    automaticSelectionFinishedOn: automaticSelectionFinishedOn ? new Date(automaticSelectionFinishedOn) : null,
    productVariants: products.map((product) => {
      const selectedProductVariant = product.productVariants.find(
        (productVariant) => productVariant.isSelected,
      ) as SelectionProductVariantResponseDTO;

      return new ProductVariant(
        selectedProductVariant.id,
        selectedProductVariant.isCandidate ? IsCandidate.YES : IsCandidate.NO,
      );
    }),
  });

export type { SelectionResponse };
export { selectionFromResponse };
