import { FC, Fragment, useCallback } from "react";
import { InsightProjection } from "../../../../../core/projection/customerInsight/customerInsight";
import Text, { TextVariant } from "../../../../../shared/ui/uiKit/atoms/text/Text";
import Button from "../../../../../shared/ui/uiKit/atoms/button/Button";
import { IconVariant } from "../../../../../shared/ui/uiKit/atoms/icon/Icon";
import { useIntl } from "@lookiero/i18n-react";
import cdnImageUrl from "../../../../_util/cdnImageUrl";
import "./customer-insights.css";

const CUSTOMER_INSIGHTS_IMAGE_WIDTH = 95;

interface CustomerInsightsProps {
  readonly customerInsights: InsightProjection[];
  readonly onClick: (familyIds: string[]) => void;
  readonly onClickProductVariant: (productVariantId: string) => void;
}

const CustomerInsights: FC<CustomerInsightsProps> = ({ customerInsights, onClick, onClickProductVariant }) => {
  const { formatMessage } = useIntl();

  const handleOnClick = useCallback(
    (customerInsight: InsightProjection) => {
      const familyIds = customerInsight.families.map((family) => family.id);

      onClick(familyIds);
    },
    [onClick],
  );

  return (
    <section aria-label="customer-insights" className="customer-insights">
      {customerInsights.map((customerInsight) => (
        <div
          key={customerInsight.category}
          aria-label="customer-insight"
          className="customer-insights__insight"
          role="listitem"
        >
          <div className="customer-insights__insight-header">
            <Text className="customer-insights__insight-header-title">
              {formatMessage({ id: customerInsight.category })}:{" "}
              {customerInsight.families.map((family, index) => (
                <Fragment key={family.id}>
                  <Text className="customer-insights__insight-header-content" variant={TextVariant.BODY_BOLD}>
                    {formatMessage({ id: family.translationKey })}
                  </Text>
                  {index < customerInsight.families.length - 1 ? ", " : ""}
                </Fragment>
              ))}
            </Text>

            <Button
              aria-label="customer-insight-button"
              className="customer-insights__insight-header-button"
              icon={IconVariant.CHEVRON_RIGHT}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => handleOnClick(customerInsight)}
            />
          </div>

          <div className="customer-insights__insights-content">
            {customerInsight.productVariants.map((productVariant) => (
              <img
                key={productVariant.id}
                alt={productVariant.id}
                aria-label="customer-insight-product-variant-image"
                className="customer-insights__insights-content-image"
                src={cdnImageUrl({ url: productVariant.image, width: CUSTOMER_INSIGHTS_IMAGE_WIDTH })}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => onClickProductVariant(productVariant.id)}
              />
            ))}
          </div>
        </div>
      ))}
    </section>
  );
};

export { CustomerInsights };
