const SHORTLIST_I18N_PREFIX = "labs.shortlist.";

enum ShortlistI18n {
  NOT_INFORMED = "not_informed",
  CUSTOMER = "customer",
  SELECTION = "selection",
  ADD_TO_SELECTION = "product.add_to_selection",
  REMOVE_FROM_SELECTION = "product.remove_from_selection",
  ADD_TO_COMPARER = "product.add_to_comparer",
  COMPARE = "compare",
  PRODUCT_DESCRIPTION_AND_CHARACTERISTICS = "product.description_and_characteristics",
  PRODUCT_DESCRIPTION = "product.description",
  PRODUCT_CHARACTERISTICS = "product.characteristics",
  PRODUCT_OTHERS = "product.others",
  MODEL_MORPHOLOGY = "product.model_morphology",
  MODEL_WEIGHT = "product.model.weight",
  MODEL_HEIGHT = "product.model.height",
  MODEL_TOP_SIZE = "product.model.top_size",
  MODEL_BOTTOM_SIZE = "product.model.bottom_size",
  MODEL_SHOE_SIZE = "product.model.shoe_size",
  MODEL_BRA_SIZE = "product.model.bra_size",
  MODEL_CHEST_CONTOUR = "product.model.chest_contour",
  MODEL_WAISTLINE = "product.model.waistline",
  MODEL_HIP_CONTOUR = "product.model.hip_contour",
  MODEL_TESTED_SIZE = "product.model.tested_size",
}

export { SHORTLIST_I18N_PREFIX };

export default ShortlistI18n;
