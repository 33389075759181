import ProductVariant from "./ProductVariant";

class SelectionProductVariants {
  public productVariants: ProductVariant[];

  public constructor(productVariants: ProductVariant[]) {
    this.productVariants = productVariants;
  }

  public markAsCandidate(productVariantId: string): void {
    this.isProductVariantNotSelected(productVariantId, this.productVariants);

    const productVariant = this.productVariantById(productVariantId);
    productVariant.markAsCandidate();
  }

  public unmarkAsCandidate(productVariantId: string): void {
    this.isProductVariantNotSelected(productVariantId, this.productVariants);

    const productVariant = this.productVariantById(productVariantId);
    productVariant.unmarkAsCandidate();
  }

  private isProductVariantNotSelected(productVariantId: string, productVariants: ProductVariant[]): void | never {
    if (!productVariants.find((selectionProductVariant) => selectionProductVariant.id === productVariantId)) {
      throw new Error("Product variant does not exist in the Selection");
    }
  }

  private productVariantById(productVariantId: string): ProductVariant | never {
    const productVariant = this.productVariants.find(
      (selectionProductVariant) => selectionProductVariant.id === productVariantId,
    );

    if (!productVariant) {
      throw new Error("Product variant does not exist in the Selection");
    }

    return productVariant;
  }

  public productVariantIds(): string[] {
    return this.productVariants.map((productVariant) => productVariant.id);
  }
}

export default SelectionProductVariants;
