import IsCandidate from "./IsCandidate";

class ProductVariant {
  public readonly id: string;
  public isCandidate: IsCandidate;

  public constructor(id: string, isCandidate: IsCandidate = IsCandidate.NO) {
    this.id = id;
    this.isCandidate = isCandidate;
  }

  public markAsCandidate(): void {
    this.isCandidate = IsCandidate.YES;
  }

  public unmarkAsCandidate(): void {
    this.isCandidate = IsCandidate.NO;
  }
}

export default ProductVariant;
