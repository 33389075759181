import { DomainEvent as DomainEventJS } from "@lookiero/messaging.js";
import ProductVariantMarkedAsCandidate from "../../../../domain/selection/event/ProductVariantMarkedAsCandidate";
import ProductVariantUnmarkedAsCandidate from "../../../../domain/selection/event/ProductVariantUnmarkedAsCandidate";
import { UseQueryFunctionResult } from "@lookiero/messaging-react";
import { SelectionAlertProjection } from "../../../../projection/alert/selectionAlert";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { listSelectionAlertsByBoxNumber } from "../../../../projection/alert/listSelectionAlertsByBoxNumber";
import { useMessagingJsBridgeQuery } from "../../_messagingJsBridge/useMessagingJsBridgeQuery";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import { isProductVariantDeselected } from "../../../../domain/selection/model/ProductVariantDeselected";
import { isProductVariantReplaced } from "../../../../domain/selection/model/ProductVariantReplaced";
import { isProductVariantSelected } from "../../../../domain/selection/model/ProductVariantSelected";

const shouldInvalidate = (event: DomainEvent<MessageName>) =>
  isProductVariantDeselected(event) || isProductVariantReplaced(event) || isProductVariantSelected(event);

interface UseListSelectionAlertsByBoxNumberFunctionArgs {
  readonly queryId?: string | string[];
  readonly boxNumber: string;
}

interface UseListSelectionAlertsByBoxNumberFunction {
  (args: UseListSelectionAlertsByBoxNumberFunctionArgs): UseQueryFunctionResult<SelectionAlertProjection[]>;
}

const useListSelectionAlertsByBoxNumber: UseListSelectionAlertsByBoxNumberFunction = ({ queryId, boxNumber }) =>
  useMessagingJsBridgeQuery({
    id: queryId,
    contextId: MESSAGING_CONTEXT_ID,
    query: listSelectionAlertsByBoxNumber({ boxNumber }),
    invalidation: shouldInvalidate,
    messagingJsInvalidation: (event: DomainEventJS) =>
      event instanceof ProductVariantMarkedAsCandidate || event instanceof ProductVariantUnmarkedAsCandidate,
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  });

export { useListSelectionAlertsByBoxNumber };
