type ViewSelectionByLegacyBoxIdRequestPayload = {
  readonly legacy_box_id: string;
};
const viewSelectionByLegacyBoxIdRequestPayload = (legacyBoxId: string): ViewSelectionByLegacyBoxIdRequestPayload => ({
  legacy_box_id: legacyBoxId,
});

type MarkProductVariantAsCandidateRequestPayload = {
  readonly selection_id: string;
  readonly product_variant_id: string;
};
const markProductVariantAsCandidateRequestPayload = (
  selectionId: string,
  productVariantId: string,
): MarkProductVariantAsCandidateRequestPayload => ({
  selection_id: selectionId,
  product_variant_id: productVariantId,
});

type UnmarkProductVariantAsCandidateRequestPayload = {
  readonly selection_id: string;
  readonly product_variant_id: string;
};
const unmarkProductVariantAsCandidateRequestPayload = (
  selectionId: string,
  productVariantId: string,
): UnmarkProductVariantAsCandidateRequestPayload => ({
  selection_id: selectionId,
  product_variant_id: productVariantId,
});

export {
  viewSelectionByLegacyBoxIdRequestPayload,
  markProductVariantAsCandidateRequestPayload,
  unmarkProductVariantAsCandidateRequestPayload,
};
