import { useDispatch } from "@lookiero/messaging.js";
import { useCallback, useState } from "react";
import AsyncActionState from "../../../shared/ui/uiKit/_common/AsyncActionState";
import MarkProductVariantAsCandidate from "../../../core/domain/selection/command/markProductVariantAsCandidate/MarkProductVariantAsCandidate";
import invariant from "ts-invariant";
import { ProductVariantProjection } from "../../../core/projection/product/productVariant";

type UseMarkProductVariantAsCandidateParameters = {
  readonly legacyBoxId: string;
  readonly selectionId?: string;
  readonly boxId: string;
};
type UseMarkProductVariantAsCandidateReturn = {
  readonly state: AsyncActionState;
  readonly markProductVariantAsCandidate: (productVariant: ProductVariantProjection) => Promise<void>;
};
const useMarkProductVariantAsCandidate = ({
  legacyBoxId,
  selectionId,
  boxId,
}: UseMarkProductVariantAsCandidateParameters): UseMarkProductVariantAsCandidateReturn => {
  const dispatch = useDispatch();
  const [state, setState] = useState(AsyncActionState.DEFAULT);

  const markProductVariantAsCandidate = useCallback(
    async (productVariant: ProductVariantProjection) => {
      invariant(selectionId, "markProductVariantAsCandidate cannot be called without a Selection");

      try {
        setState(AsyncActionState.PENDING);

        await dispatch(
          new MarkProductVariantAsCandidate({
            legacyBoxId,
            selectionId,
            productVariantId: productVariant.id,
            boxId,
          }),
        );

        setState(AsyncActionState.SUCCESS);
      } catch (error) {
        console.log(error);
        setState(AsyncActionState.ERROR);
      }
    },
    [dispatch, legacyBoxId, selectionId, boxId],
  );

  return { state, markProductVariantAsCandidate };
};

export default useMarkProductVariantAsCandidate;
