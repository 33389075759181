import { AggregateRoot } from "@lookiero/messaging.js";
import ProductVariantMarkedAsCandidate from "../event/ProductVariantMarkedAsCandidate";
import ProductVariantUnmarkedAsCandidate from "../event/ProductVariantUnmarkedAsCandidate";
import ProductVariant from "./ProductVariant";
import SelectionProductVariants from "./SelectionProductVariants";

type SelectionParameters = {
  readonly legacyBoxId: string;
  readonly selectionId: string;
  readonly boxId: string;
  readonly automaticSelectionStartedOn: Date | null;
  readonly automaticSelectionFinishedOn: Date | null;
  readonly productVariants: ProductVariant[];
};

class Selection extends AggregateRoot {
  public readonly selectionId: string;
  public readonly boxId: string;
  public readonly legacyBoxId: string;
  public readonly selectionProductVariants: SelectionProductVariants;
  public readonly automaticSelectionStartedOn: Date | null;
  public readonly automaticSelectionFinishedOn: Date | null;

  public constructor({
    legacyBoxId,
    selectionId,
    boxId,
    automaticSelectionStartedOn,
    automaticSelectionFinishedOn,
    productVariants,
  }: SelectionParameters) {
    super();

    this.selectionId = selectionId;
    this.boxId = boxId;
    this.legacyBoxId = legacyBoxId;
    this.automaticSelectionStartedOn = automaticSelectionStartedOn;
    this.automaticSelectionFinishedOn = automaticSelectionFinishedOn;
    this.selectionProductVariants = new SelectionProductVariants(productVariants);
  }

  public markProductVariantAsCandidate(productVariantId: string): void {
    this.selectionProductVariants.markAsCandidate(productVariantId);

    this.record(new ProductVariantMarkedAsCandidate(this.selectionId, productVariantId));
  }

  public unmarkProductVariantAsCandidate(productVariantId: string): void {
    this.selectionProductVariants.unmarkAsCandidate(productVariantId);

    this.record(new ProductVariantUnmarkedAsCandidate(this.selectionId, productVariantId));
  }
}

export default Selection;
