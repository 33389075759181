import { useDispatch } from "@lookiero/messaging.js";
import { useCallback, useState } from "react";
import AsyncActionState from "../../../shared/ui/uiKit/_common/AsyncActionState";
import UnmarkProductVariantAsCandidate from "../../../core/domain/selection/command/unmarkProductVariantAsCandidate/UnmarkProductVariantAsCandidate";
import invariant from "ts-invariant";
import { ProductVariantProjection } from "../../../core/projection/product/productVariant";

type UnmarkProductVariantAsCandidateParameters = {
  readonly legacyBoxId: string;
  readonly selectionId?: string;
  readonly boxId: string;
};
type UnmarkProductVariantAsCandidateReturn = {
  readonly state: AsyncActionState;
  readonly unmarkProductVariantAsCandidate: (productVariant: ProductVariantProjection) => Promise<void>;
};
const useUnmarkProductVariantAsCandidate = ({
  legacyBoxId,
  selectionId,
  boxId,
}: UnmarkProductVariantAsCandidateParameters): UnmarkProductVariantAsCandidateReturn => {
  const dispatch = useDispatch();
  const [state, setState] = useState(AsyncActionState.DEFAULT);

  const unmarkProductVariantAsCandidate = useCallback(
    async (productVariant: ProductVariantProjection) => {
      invariant(selectionId, "unmarkProductVariantAsCandidate cannot be called without a Selection");

      try {
        setState(AsyncActionState.PENDING);

        await dispatch(
          new UnmarkProductVariantAsCandidate({
            legacyBoxId,
            selectionId,
            productVariantId: productVariant.id,
            boxId,
          }),
        );

        setState(AsyncActionState.SUCCESS);
      } catch (error) {
        console.log(error);
        setState(AsyncActionState.ERROR);
      }
    },
    [dispatch, legacyBoxId, selectionId, boxId],
  );

  return { state, unmarkProductVariantAsCandidate };
};

export default useUnmarkProductVariantAsCandidate;
